@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
}

body {
    position: relative;
}

.navbarCont {
    position: fixed;
    display: flex;
    padding: 2vw;
    padding-top: 3vw;
    background-color: #004aad;
    align-items: center;
    justify-content: space-between;
    left: 0;
    right: 0;
    z-index: 1100;
}
.logo{
    width: 35vw;
    margin-left: 1vw;
}
.logo img {
    width: 100%;
    height: 100%;
}

.btnMostrarOpciones{
    display: flex;
    flex-direction: column;
    padding: 0.5vw;
    margin-right: 1vw;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.lineaBtn{
    position: relative;
    background-color: #fff;
    width: 5vw;
    height: 1vw;
    margin-bottom: 1vw;
    transition: all 0.2s;
}
.lineaTop {
    bottom:-1.9vw;
    transform: rotate(-50deg);
}
.lineaBottom{
    transform: rotate(50deg);
}

.opciones{
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    z-index: 1000;
    width: 100vw;
}

.logoOpciones{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3vw;
    margin-bottom: 3vw;
    width: 50%;
    background-color: #004aad;
    margin-bottom: 0;
}
.logoOpciones img{
    width: 100%;
}

.opcionesBtnCont {
    display:flex;
    flex-direction: column;
    width:100%;
    background-color:rgba(255, 255, 255, 0.2);
    padding: 1vw;
}

.btnOpcion {
    display: flex;
    align-items: center;
    padding: 2vw;
    color:#fff;
    font-weight: 500;
    margin-bottom: 1vw;
    font-size: 3.5vw;
    cursor: pointer;
}
.btnOpcion img {
    width:5.5vw;
    margin-right: 2vw;
}

.contImgPerfil {
    display: flex;
    align-items: center;
    width: 19vw;
    height: 19vw;
    border-radius: 100%;    
    z-index: 1;
    background-repeat: no-repeat;
    background-size: cover;
}

.imgCamara{
    top: 10vw;
    right: 5vw;
    position: absolute;
    width: 8vw!important;
}

.txtNameUser{
    margin-top: 1vw;
    font-weight: 600;
    color:#fff;
    font-size: 4vw;
    text-align: center;
    line-height: 4vw!important;
    margin-bottom: 2vw;
}

.opcionesUserCont{
    display: flex;
    width: 100%;
    padding: 2vw;
    padding-left: 1.7vw;
    background-color: transparent;
    flex-direction: column;
}
.opcionUser {
    margin-bottom: 4vw;
    display: flex;
    background-color: #fff;
    padding: 1.5vw;
    width: 80%;
    align-items: center;
    justify-content: center;
}

.opcionUser p {
    font-weight: 700;
    font-size: 3.2vw;
}

.opcionActive p{
    color:#004aad;
    text-align: center;
    border-bottom: 1vw solid #004aad;
}

.btnToggleMenuCont{
    z-index: 1000;
    position: fixed;
    left: 63vw;
    top: 43vh;
    padding: 2vw;
}
.toggleMenu{
    z-index: 1700;
    width: 0;
    height: 0;
    border-top: 6vw solid transparent;
    border-bottom: 6vw solid transparent; 
}

@media screen and (min-width: 530px) {
    .logo{
        cursor: pointer;
        width:11vw;
        margin-top: 0.4vw;
    }
    .contOpcionesOff{
        margin-top: auto!important;
        margin-bottom: 1.5vw!important;
    }
    .opciones{
        z-index: 1000;
        width: 20vw;
    }
    .navbarCont {
        padding: 0;
        z-index: 1100;
    }
    .logoOpciones{
        margin-top: 0;
        margin-bottom: 0;
    }
    .btnMostrarOpciones{
        display: none;
    }
    .lineaBtn{
        height: 0.3vw;
        margin-bottom: 0.5vw;
        width: 1.7vw;
    }
    .contImgPerfil{
        margin-top: 2vw;
        width: 5.7vw;
        height: 5.7vw;
        background-size:cover;
        background-position: 50%;
    }
    .imgCamara{
        cursor: pointer;
        left: 5.2vw;
        top: 6vw;
        width: 2vw!important;
    }
    .txtNameUser{
        font-size: 1.2vw;
        width: 100%;
        text-align: center;
        line-height: 1.5vw!important;
        margin-bottom: 0.9vw;
        margin-top: 0.5vw;
    }
    .opcionUser{
        width: 100%;
        padding: 0.3vw;
        margin-bottom: 1vw;
        cursor: pointer;
    }
    .opcionUser p {
        font-size: 0.9vw;
    }
    .opcionActive p{
        border-bottom: 0.3vw solid #004aad;
    }
    .opcionesUserCont{
       padding-bottom: 0;
       padding-top: 0;
    }
    .btnOpcion img{
        width: 2vw;
        margin-right: 0.7vw;
    }
    .btnOpcion {
        font-size: 1vw;
        padding: 0;
        padding-left: 1vw;
        margin: 0;
        height: 3vw;
        width: 82%;
        margin-left: 1.5vw;
    }
    .opcionesBtnCont{
        padding: 0;
        margin: 0;
    }

}

