.cargaCont{
    position: fixed;
    background-color: #fff;
    z-index: 1500;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.cargaCont img {
    margin-top: 7vw;
    width: 9vw;
}

.LogoPantallaCarga img{
    width: 40vw;
    margin-top: 0;
}

@media screen and (min-width:530px) {
    .cargaCont{
        z-index: 1700;
    }
    .cargaCont img {
        width: 2.3vw;
        margin-top: 1.4vw;
    }
    .LogoPantallaCarga img{
        width: 15vw;
        margin-top: 0;
    }
}