html{
    scroll-behavior: smooth;
}

.inicioCont{
    display: flex;
    flex-direction: column;
    background-color: #fff;
    align-items: center;
    padding-top: 15vw;
}

.ilustracion1 {
    width: 65%;
}
.subTitulo h2{
    font-size: 6vw;
    line-height: 7.4vw;
    width: 100%;
    text-align: center;
    margin-bottom: 10vw;
    margin-top: 3vw;
    font-weight: 700;
    color: #004aad;
}

.section{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 100;
}
.section h2 {
    margin-top: 10vw;
    text-align: center;
    font-weight: 700;
    color:#004aad;
    width: 90%;
    font-size: 7vw;
}
.section p {
    margin-top: 3vw;
    width: 80%;
    color: #000;
    font-weight: 400;
    text-align: center;
    font-size: 4vw;
}
.btnComenzar{
    width: 70%;
    display: flex;
    padding: 3vw;
    justify-content: center;
    border-radius: 2vw;
    background-color: #004aad;
    color: #fff;
    font-size: 4.5vw;
    margin-top: 7vw;
    margin-bottom: 5vw;
    text-decoration: none;
    cursor: pointer;
}

.section1 {
    /* position:absolute;
    width: 100vw;
    top: 15vw;
    height: 482vw;
    opacity: 0.15;
    z-index: 10; */
}
.ventajasYbeneficios {
    margin-top: 8vw;
    /* background-color: rgba(196, 196, 196, 0.35); */
}
.ventajasYbeneficios span {
    font-size: 6.5vw;
    margin-top: 3vw;
    margin-bottom: 3vw;
    font-weight: 600;
    color: #000;
}

.imagenesVentajas{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.ventaja {
    width: 33.33%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 6vw;
}
.ventajaMedio{
    margin: 0 2vw;
}
.ventaja h3 {
    text-align: center;
    color:#000;
    font-size: 3vw;
    font-weight: 700;
}
.imgVentaja {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 10vw; */
    background-color: #fff;
    height: 21vw;
    width: 21vw;
    margin-bottom: 2.5vw;
}
.imgVentaja img {
    /* display: none; */
    height: auto;
    width: 90%;
}

.section2 {
    margin-top: 2vw;
}

.section2 h2 {
    color:#004aad;
    font-weight: 7  00;
    margin-bottom: 5vw;
}
.section2 span {
    color: #004aad;
    font-weight: bold;
    font-size: 2.3vw;
}
.cosasVender {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
}
.cosasVender div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33.33%;
}
.cosasVender div div {
    width: 21vw;
    height: 21vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
.cosasVender div img {
    width: 90%;
    height: auto;
}
.cosasVender div p {
    width: 100%;
    color: #474747;
}

.cosasVender h3 {
    font-weight: 700;
    text-align: center;
    font-size: 3vw;
}

.section3 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 90%;
    background-color: #F9F9F9;
    margin-top: 10vw;
    border-radius: 5vw;
    margin-bottom: 10vw;
    z-index: 100;
}
.section3 span{
    width: 100%;
}
.section3 label{
    width: 100%;
}

.section3 h2 {
    color:#004aad;
    font-weight: 600;
    margin: 5vw 0;
    margin-bottom: 2vw;
}
.section3 form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 70%;
}
.section3 input {
    padding: 1vw;
    width: 100%;
    margin: 3vw 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.2vw solid #666666;
}
.section3 textarea {
    padding: 1vw;
    width: 100%;
    height: 18vw;
    margin: 3vw 0;
    background-color: transparent;
    border: none;
    border-bottom: 0.2vw solid #666666;
    resize:none;
}
.section3 input:focus {
    outline: none;
    border-bottom: 0.2vw solid #004aad;
}
.section3 textarea:focus{
    outline: none;
    border-bottom: 0.2vw solid #004aad;
}

.btnContacto{
    border-radius: 3vw;
    background-color: #004aad;
    color:#fff;
    width: 100%;
    padding: 2vw;
    display: flex;
    border: none;
    align-items: center;
    justify-content: center;
    margin-top: 4vw;
    margin-bottom: 10vw;
    cursor: pointer;
}

.section4 {
    margin-top: 15vw;
}
.subSection4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.section4 h2{
    padding: 0 5vw;
}
.subSection4 img {
    width: 65%;
}
.subSection4 p {
    text-align: center;
    font-weight: 500;
    width: 80%;
    font-size: 3.8vw;
}

.section5{
    margin-top: 8vw;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}
.beneficio {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 2vw 4vw;
}
.beneficio p {
    font-weight: 400;
    font-size: 3.5vw;
}
.beneficio img {
    width: 20%;
    margin-right: 1.5vw;
}

.section6 {
    margin-top: 10vw;
}
.section6 h2 {
    margin-bottom: 5vw;
}
.subSection6{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.subSection6 div {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3vw;
}
.subSection6 span {
    font-weight: 600;
    font-size: 6vw;
}
.subSection6 p {
    width: 80%;
    text-align: center;
    font-weight: 400;
}
.subSection6 img {
    border-radius: 25vw;
    width: 35%;
}

.section7 {
    margin-bottom: 4vw;
}
.section7 h2 {
    margin-bottom: 5vw;
}
.redes {
    display: flex;    
    padding-left: 3vw;
}
.red {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin-right: 0;
    width: 25%;
}
.red img {
    width: 25%;
    margin-bottom: 1vw;
}
.red p {
    font-size: 2vw;
    text-align: center;
}

.section8{
    display: flex;
    flex-direction: row;
    margin-top: 12vw;
    justify-content: flex-end;
}
.section8 div:first-child{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
}
.section8 div:last-child{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 40%;
}
.section8 h2 {
    margin-bottom: 0;
    margin-bottom: 2vw;
    font-size: 5.5vw;
    line-height: 6vw;
    width: 95%;
}
.section8 p {
    font-size: 3.3vw;
    text-align: center;
    width: 95%;
}
.section8 img {
    width: 100%;
}

.section9 {
    margin-top: 12vw;
}
.titulo9 {
    display: flex;
    align-items: center;
    justify-content: center;
}
.titulo9 h2 {
    margin: 0;
    width: auto;
    margin-right: 1vw;
}
.titulo9 img {
    width: 8vw;
}
.descripcion9 {
    margin-top: 1.5vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.descripcion9 img {
    width: 40vw;
}
.descripcion9 p {
    width: 80%;
    margin-top: 1vw;
    font-size: 3.3vw;
}

.section10 {
    margin: 12vw 0 10vw 0;
}
.item10 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 8vw;
}
.item10 h2 {
    margin: 0;
    margin-bottom: 2vw;
    font-size: 4.8vw;
}
.txtCalendario{
    width: 100%;
    margin-left: 1.5vw;
    font-size: 3.4vw;
}
.item10 span {
    color:#004aad;
    font-weight: 600;
}
.item10_info {
    width: 85%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.item10_info img {
    width: 27vw;
    height: 25vw;
}
.item10_info div {
    margin-left: 1.5vw;
}

@media screen and (min-width:530px) {
    .inicioCont {
        width:70vw;
        padding: 0;
        margin: 0;
        padding-top: 0;
        margin-left: 24vw;
    }
    .subTitulo h2{
        font-size: 2.2vw;
        line-height: 3.4vw;
        width: 100%;
        text-align: center;
        margin-bottom: 4vw;
        font-weight: 700;
        margin-top: 0;
    }
    .ilustracion1 {
        width: 40%;
    }
    .section {
        margin-top: 6vw;
    }
    .section h2{
        width: 100%;
        font-size: 2.8vw;
    }
    .section p {
        margin-top: .7vw;
        font-size: 1.25vw;
        font-weight: 700;
    }
    .section1 {
        width: 70vw;
    }
    .btnComenzar {
        width: 15%;
        padding: 1vw;
        margin: 0;
        margin-top: 1vw;
    }
    .btnComenzar span {
        font-size: 1.1vw;
    }

    .ventajasYbeneficios {
        margin-top: 6vw;
    }
    .ventajasYbeneficios span {
        font-size: 2.4vw;
        margin-top: 0;
    }
    .imgVentaja{
        width: 7.5vw;
        height: 6vw;
        margin-bottom: 1.7vw;
    }
    .ventaja {
        margin-bottom: 3.5vw;
    }
    .ventaja h3 {
        font-size: 1vw;
    }

    .section2 {
        margin-top: 3vw;
        width: 100%;
    }
    .section2 span {
        font-size: 1vw;
    }
    .section h2 {
        margin-top: 0vw;
    }
    .cosasVender div {
        width: 20%;
    }
    .cosasVender div div {
        width: 6.5vw;
        height: 5vw;
        margin-bottom: 1.7vw;
    }
    .cosasVender div img {
        width: 90%;
        height: auto;
    }
    .cosasVender h3 {
        font-size: 1vw;
    }

    .section3 {
        padding: 0;
        margin-top: 3vw;
        margin-bottom: 0;
    }
    .section3 h2 {
        margin-bottom: 0.5vw;
        margin-top: 2vw;
    }
    .section3 input {
        width: 80%;
        margin-bottom: 0;
        margin-top: 1.4vw;
        padding: 0.6vw;
        font-size: 1vw;
    }
    .section3 span{
        width: 80%;
    }
    .section3 label{
        width: 80%;
    }
    .section3 textarea {
        width: 80%;
        height: 20%;
        padding: 0.6vw;
        font-size: 1vw;
        margin-top: 1.2vw;
        border-bottom: 1px solid #666666;
    }
    .section3 input{
        border-bottom: 1px solid #666666;
    }
    .section3 input:focus {
        border-bottom: 1px solid #004aad;
    }
    .section3 textarea:focus{
        border-bottom: 1px solid #004aad;
    }

    .btnContacto{
        margin-top: 2vw;
        margin-bottom: 4vw;
        width: 40%;
        padding: 0.5vw;
        font-size: 1.2vw;
    }

    .section4 {
        margin-top: 8vw;
    }
    .subSection4 {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .subSection4 img{
        width: 32%;
        margin-right: 2vw;
    }
    .subSection4 p {
        width: 35%;
        font-weight: 600;
        font-size: 1.3vw;
    }
    
    .section5{
        margin-top: 4vw;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }
    .beneficio {
        display: flex;
        align-items: center;
        width: 46%;
        margin: 0 2vw 3vw 0;

    }
    .beneficio p {
        font-weight: 400;
        font-size: 1.1vw;
    }
    .beneficio img {
        width: 15%;
        margin-right: 1.5vw;
    }

    .section6{
        margin-top: 3vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .section6 h2 {
        margin-bottom: 2.5vw;
    }
    .subSection6{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
    }
    .subSection6 img {
        width: 35%;
        border-radius: 10vw;
        
    }
    .subSection6 span {
        font-weight: 600;
        font-size: 2vw;
    }
    .subSection6 p {
        font-weight: 500;
        color:#515151;
    }

    .section7 {
        margin-top: 5vw;
        display: flex;
        flex-direction: column;
        margin-bottom: 3vw;
    }
    .section7 h2{
        margin-bottom: 2vw;
    }
    .redes{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 78vw;
    }
    .red {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5vw;
        border-radius: 0.5vw;
        cursor: pointer;
        flex-direction: row;
    }
    .red:hover{
        background-color: #f3f3f3;
    }
    .red img {
        width: 11%;
        margin: 0;
        margin-right: 0.5vw;
    }
    .red p {
        font-weight: 400;
        font-size: 1.1vw;
        color: rgb(56, 56, 56);
    }

    .section8 {
        padding: 0;
        margin-top: 6vw;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .section8 div:first-child {
        width: 45%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .section8 p {
        text-align: left;
        width: 90%;
        font-size: 1.2vw;
    }
    .section8 div:last-child{
        
    }
    .section8 h2 {
        margin-bottom: 0;
    }
    .section8 img {
        width: 20vw;
    }

    .section9 {
        margin-top: 7vw;
        display: flex;
        flex-direction: column;
    }
    .titulo9{
        display: flex;
        justify-content: center;
    }
    .titulo9 h2 {
        width: auto;
        margin: 0;
        margin-right: 0.4vw;
    }
    .titulo9 img {
        width: 3.2vw;
        height: 3.2vw;
    }
    .descripcion9 {
        display: flex;
        margin-top: 0.5vw;
        align-items: center;
        flex-direction:  row;
    }
    .descripcion9 img {
        width: 17vw;
        margin-right: 1vw;
    }
    .descripcion9 p {
        font-size: 1vw;
    }

    .section10 {
        display: flex;
        flex-direction: column;
        margin-top: 6vw;
        margin-bottom: 0;
    }
    .titulo10 {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 1vw;
    }
    .subSection10 h2 {
        margin: 0;
        font-size: 1.7vw;
    }
    .subSection10 {
        display: flex;
    }
    .item10{
        display: flex;
        flex-direction: column;
        width: 50%;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }
    .item10_info {
        display: flex;
        flex-direction: row;
    }
    .txtCalendario{
        font-size: 1vw;
    }    
    .item10 img {
        width: 10vw;
        margin-right: 1vw;
        height: auto;
    }
    .item10 span {
        color:#004aad;
        font-weight: 600;
    }
}
